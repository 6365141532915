








































































































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AttachmentDto,
  AttachmentHostType,
  AuditFlowScope,
  BeneficiaryDto,
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import ExportWord from "@/components/DownloadWord/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

@Component({
  name: "beneficiaryDetail",
  components: {
    ExportWord,
    AttachmentsView,
    AuditBlock,
  },
})
export default class BeneficiaryDetail extends Vue {

  dataId?: number;
  detail: BeneficiaryDto = {};
  styles = "";
  selector = "table-detail-world";
  filename = "受益人基本信息表";
  attachmentList: AttachmentDto[] = [];
  hostType = AttachmentHostType.Beneficiary;

  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.fetchDetail();
      this.getAttachments();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.beneficiary.get({ id: this.dataId }).then((res) => {
      this.detail = { ...res };
    });
  }

  // 获取附件
  async getAttachments() {
    await api.attachmentService
      .getAttachments({
        hostId: this.dataId!.toString(),
        hostType: AttachmentHostType.Beneficiary,
      })
      .then((res) => {
        this.attachmentList = res;
      });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }

  fetchData() {
    return api.beneficiary.audit;
  }

}
