

























































































import { Component, Ref, Vue } from "vue-property-decorator";
import CustomFormDataTableDetail from "@/components/CustomForm/TableDetail/index.vue";
import {
  AuditFlowScope,
  CustomFormType,
  FamilyMembersInfoDto,
} from "@/api/appService";
import api from "@/api/index";

import ExportWord from "@/components/DownloadWord/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

@Component({
  name: "CustomFormStandardDetail",
  components: {
    CustomFormDataTableDetail,
    ExportWord,
    AttachmentsView,
    AuditBlock,
  },
})
export default class CustomFormStandardDetail extends Vue {

  private formId!: string;
  private dataId!: string;
  private hostType: CustomFormType = CustomFormType.Beneficiary;
  private isProject = false;
  isReady = false;

  styles = "";
  selector = "detail-wrap";
  filename = "项目受益人详情";
  projectName: string | undefined = "";
  familyMembersInfoList: FamilyMembersInfoDto[] = [];

  created() {
    this.formId = this.$route.query.formId as string;
    this.dataId = this.$route.query.id as string;
    this.isProject = false;

    if (this.dataId && this.hostType) {
      this.isReady = true;
    } else {
      this.$message.error("缺少url参数 id 或 hostType，请检查!");
    }

    this.fetchAuditDetail();
  }

  fetchAuditDetail() {
    api.projectBeneficiaryMapping
      .get({ id: parseInt(this.dataId) })
      .then((res) => {
        this.projectName = res.project?.displayName;
        this.familyMembersInfoList = res.familyMembersInfoList ?? [];
      });
  }

  getData() {
    return api.projectBeneficiaryMapping.get({ id: parseInt(this.dataId) });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }


  get auditApi(){
    return {
      canAudit: api.projectBeneficiaryMapping.canAudit,
      userAudit: api.projectBeneficiaryMapping.audit
    } as AuditApi
  }

}
