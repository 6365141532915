















































































































import { Component, Vue } from "vue-property-decorator";
import CustomFormEdit from "@/components/CustomForm/Edit/index.vue";
import {
  BeneficiaryCreateOrUpdateDto,
  BeneficiaryDto,
  BeneficiaryDtoPagedResultDto,
  CustomFormType,
  ProjectBeneficiaryMappingCreateOrUpdateDto,
  FamilyMembersInfoDto,
  ProjectBeneficiaryMappingDto,
} from "@/api/appService";
import api from "@/api";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  name: "CreateInternalProjectBeneficiary",
  components: {
    CustomFormEdit,
    AbSelect,
  },
})
export default class CreateInternalProjectBeneficiary extends Vue {
  dataId = 0;
  projectId!: number;
  formId!: number;

  isShow = false;
  canEdit = true;
  beneficiary: BeneficiaryCreateOrUpdateDto = {};
  beneficiaryList: BeneficiaryDto[] = [];
  familyMembersInfoList: FamilyMembersInfoDto[] = [];

  created() {
    api.beneficiary.getAll({ maxResultCount: 10000 }).then((res) => {
      this.beneficiaryList = res.items ? res.items : [];
    });
    // 自定义表单id
    if (this.$route.query.formId) {
      this.formId = Number(this.$route.query.formId);
    }
    // 内部项目id
    if (this.$route.query.projectId) {
      this.projectId = Number(this.$route.query.projectId);
    }
    // mapping关系的id
    if (this.$route.query.id) {
      this.dataId = Number(this.$route.query.id);
      this.isShow = true;
      this.canEdit = false;
    }
    // 受益人id
    if (this.$route.query.beneficiaryId) {
      this.beneficiary.id = Number(this.$route.query.beneficiaryId);
    }
  }

  get hostType() {
    return CustomFormType.Beneficiary;
  }

  chooseBeneficiary() {
    this.isShow = false;
    api.beneficiary.get({ id: this.beneficiary.id }).then((res) => {
      this.beneficiary = res;
      this.familyMembersInfoList = res.familyMembersInfoList ?? [];
      this.isShow = true;
      this.thirdData;
    });
  }

  get getData(): Promise<ProjectBeneficiaryMappingDto> {
    if (!this.dataId) {
      return new Promise<ProjectBeneficiaryMappingDto>((resolve) =>
        resolve({})
      );
    } else {
      return api.projectBeneficiaryMapping.get({ id: this.dataId });
    }
  }

  get thirdData() {
    return this.beneficiary;
  }

  handleSave() {
    (this.$refs.customForm as any).validate(async (valid: boolean) => {
      if (valid) {
        const data = (
          this.$refs.customForm as any
        ).getRequestData() as ProjectBeneficiaryMappingCreateOrUpdateDto;
        data.projectId = this.projectId;
        data.beneficiaryId = this.beneficiary.id;
        data.formId = this.formId;
        data.familyMembersInfoList = this.familyMembersInfoList;

        if (this.dataId) {
          api.projectBeneficiaryMapping
            .update({ body: data })
            .then((res) => {
              this.$message.success("更新成功");
              this.$router.back();
            })
            .catch((error) => {
              this.canEdit = true;
            });
        } else {
          data.id = 0;
          api.projectBeneficiaryMapping.create({ body: data }).then((res) => {
            this.$message.success("更新成功");
            this.$router.back();
          });
        }
      }
    });
  }

  cancel() {
    this.$router.back();
  }

  // 增加家庭成员情况
  private addRow(r: number) {
    const obj = {
      name: undefined,
      appellation: undefined,
      birthDate: undefined,
      health: undefined,
      occupation: undefined,
      annualIncome: undefined,
    };

    this.familyMembersInfoList = [...this.familyMembersInfoList!, obj];
  }

  // 删除家庭成员情况
  private deleteRow(index: number) {
    this.familyMembersInfoList!.splice(index, 1);
  }
}
