











































































































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import moment from "moment";
import {
  AuditFlowScope,
  // AuditUserLogType,
  BeneficiaryDto,
  CustomFormType,
  DataDictionaryDto,
  InternalProjectDto,
} from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import AuditFlowDetailButton from "@/components/AuditFlowDetailButton/index.vue";
import ProvinceCityArea from "@/components/ProvinceCityArea/index.vue";

@Component({
  name: "internalProjectBeneficiaryList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
    AuditFlowDetailButton,
    ProvinceCityArea,
  },
})
export default class InternalProjectBeneficiaryList extends Vue {
  queryForm = {
    code: undefined,
    realName: undefined,
    sex: undefined,
    minAge: undefined,
    maxAge: undefined,
    nation: undefined,
    provinceId: undefined,
    cityId: undefined,
    areaId: undefined,
    townId: undefined,
    isEnjoyTheSubsistenceAllowancePolicy: undefined,
    isEnjoyOtherSalvation: undefined,
  };

  sexList: DataDictionaryDto[] = [];
  nationList: DataDictionaryDto[] = [];

  created() {
    this.fetchDataDictionary();
    this.fetchNationDataDictionary();
  }

  fetchData(params: any) {
    return api.beneficiary.getAll(params);
  }
  fetchDataDictionary() {
    api.dataDictionary
      .getDataDictionaryListByKey({ key: "Sex", maxResultCount: 1000 })
      .then((res: any) => {
        this.sexList = res.items;
      });
  }

  fetchNationDataDictionary() {
    api.dataDictionary
      .getDataDictionaryListByKey({ key: "Nation", maxResultCount: 1000 })
      .then((res: any) => {
        this.nationList = res.items;
      });
  }

  // 操作按钮
  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;
      case "edit":
        this.handleEdit($row.index, $row.item);
        break;
      case "delete":
        this.handleDelete($row.index, $row.item);
        break;
      case "startAudit":
        this.handleStartAudit($row.index, $row.item);
        break;
      case "auditFlow":
        this.handleAuditFlow($row.index, $row.item);
        break;
    }
  }

  handleCreate() {
    this.$router.push({
      name: "internalProjectBeneficiaryCreate",
    });
  }

  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "internalProjectBeneficiaryEdit",
      params: {
        id: row.id,
      },
    });
  }

  handleDetail(row: any) {
    this.$router.push({
      name: "internalProjectBeneficiaryDetail",
      params: {
        id: row.id,
      },
    });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.Beneficiary,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }

  //  提交审核
  async handleStartAudit(index: number, row: BeneficiaryDto) {
    // await api.beneficiary.startAudit({body: {id: row.id}}).then(res => {
    //     this.$message.success("已提交");
    // });
  }

  // 删除
  async handleDelete(index: number, row: BeneficiaryDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.beneficiary.delete({ id: row.id }).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }
}
